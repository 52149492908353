<template>
  <div class="container">
    <div class="form-signin" style="text-align: center">
      <img
        class="mb-4"
        src="@/assets/images/logo.svg"
        alt=""
        width="72"
        height="72"
      />
      <!--        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>-->
      <h1 class="h3 mb-3 font-weight-normal header">Dumk.in</h1>
      <!-- <label for="inputUsername" class="sr-only">Username</label> -->
      <input
        type="text"
        id="inputUsername"
        class="form-control"
        placeholder="Username"
        required
        autofocus
        v-model="loginEntered"
      />
      <!-- <label for="inputPassword" class="sr-only">Password</label> -->
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
        v-model="passwordEntered"
      />
      <!--        <div class="checkbox mb-3">-->
      <!--            <label>-->
      <!--                <input type="checkbox" value="remember-me"> Remember me-->
      <!--            </label>-->
      <!--        </div>-->
      <button class="btn btn-lg btn-primary btn-block" @click="reg">
        Sign Up
      </button>

      <!--        <br/>-->
      <!--        <button @click="vk">Sign-in with VK</button>-->

      <p class="mt-3">
        <router-link class="text-muted" to="/sign-in">Sign-in</router-link>
      </p>

      <p class="mt-5 mb-3 text-muted">
        <span>{{ $store.state.ApplicationVersion }}</span>
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import { Register } from "@/api";

export default {
  name: "Sign-Up",
  components: {
    // HelloWorld
  },
  data: function () {
    return {
      loginEntered: "",
      passwordEntered: "",
    };
  },
  methods: {
    reg: function () {
      Register(this.loginEntered, this.passwordEntered)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("userRefresh");
        })
        .catch((error) => console.error(error));
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}
.wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*html,*/
/*body {*/
/*    height: 100%;*/
/*}*/

/*body {*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    padding-top: 40px;*/
/*    padding-bottom: 40px;*/
/*    background-color: #f5f5f5;*/
/*}*/

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.header {
  font-family: "Berkshire Swash";
  font-size: 30px;

  /*margin: 50px 0;*/
  text-align: center;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>