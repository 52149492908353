import {createRouter, createWebHistory} from 'vue-router'

import Home from '@/views/Home.vue'

import SignUp from '@/views/Sign-Up.vue'
import SignIn from '@/views/Sign-In.vue'
import SignOut from '@/views/Sign-Out.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/sign-up',
        name: 'Sign Up',
        component: SignUp
    },
    {
        path: '/sign-in',
        name: 'Sign In',
        component: SignIn
    },
    {
        path: '/sign-out',
        name: 'Sign Out',
        component: SignOut
    }
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: () => import(/* webpackChunkName: "additional" */ '@/views/Registrer.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router