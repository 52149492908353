<template>
  <div class="container">
    <div class="form-signin" style="text-align: center">
      <img
        class="mb-4"
        src="@/assets/images/logo.svg"
        alt=""
        width="72"
        height="72"
      />
      <!--        <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>-->
      <h1 class="h3 mb-3 font-weight-normal header">Dumk.in</h1>
      <!-- <label for="inputUsername" class="sr-only">Username</label> -->
      <input
        type="text"
        id="inputUsername"
        class="form-control"
        placeholder="Username"
        required
        autofocus
        v-model="loginEntered"
      />
      <!-- <label for="inputPassword" class="sr-only">Password</label> -->
      <input
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
        v-model="passwordEntered"
      />
      <!--        <div class="checkbox mb-3">-->
      <!--            <label>-->
      <!--                <input type="checkbox" value="remember-me"> Remember me-->
      <!--            </label>-->
      <!--        </div>-->
      <button class="btn btn-lg btn-primary btn-block" @click="login">
        Sign in
      </button>

      <!--        <br/>-->
      <!--        <button @click="vk">Sign-in with VK</button>-->

      <p class="mt-3">
        <router-link class="text-muted" to="/sign-up">Sign Up</router-link>
      </p>

      <p class="mt-3">
        <button class="btn btn-outline-secondary btn-block" @click="vk">
          Sign-in with VK
        </button>
      </p>

      <p class="mt-3">
        <button class="btn btn-outline-secondary btn-block" @click="tg">
          Sign-in with Telegram
        </button>
      </p>

      <p class="mt-5 mb-3 text-muted">
        <span>{{ $store.state.ApplicationVersion }}</span>
      </p>
    </div>
    
        <div ref="telegram"></div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import { Login, VK, TelegramCallback } from "@/api";

export default {
  name: "Sign-In",
  components: {
    // HelloWorld
  },
  data: function () {
    return {
      loginEntered: "",
      passwordEntered: "",
    };
  },
  methods: {
    login: function () {
      Login(this.loginEntered, this.passwordEntered)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("userRefresh");
        })
        .catch((error) => console.error(error));
    },
    vk: function () {
      VK(() => {
        this.$store.dispatch("userRefresh");
      });
    },
    tg: function () {
      const telegramWidgetElement = this.$refs.telegram;

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-widget.js?15";

      // const script = document.createElement("script");
      // script.setAttribute("data-telegram-login", "Dumkin_bot");
      // script.setAttribute("data-size", "large");
      // script.setAttribute(
      // "data-auth-url",
      // "https://account.dumk.in/api/oauth/callback/telegram"
      // );
      // script.setAttribute("data-request-access", "write");

      script.onload = () => {
        console.log("tg is loaded");

        window.Telegram.Login.auth(
          { bot_id: "517232063", request_access: true },
          (data) => {
            console.log("auth requested");
            if (!data) {
              // authorization failed
            }
            // TelegramCallback(data);
            TelegramCallback(data, () => {
              this.$store.dispatch("userRefresh");
            });
            this.$store.dispatch("userRefresh");
            //window.close();
          }
        );
      };

      telegramWidgetElement.appendChild(script);
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 10vh;
}
.wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*html,*/
/*body {*/
/*    height: 100%;*/
/*}*/

/*body {*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    padding-top: 40px;*/
/*    padding-bottom: 40px;*/
/*    background-color: #f5f5f5;*/
/*}*/

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.header {
  font-family: "Berkshire Swash";
  font-size: 30px;

  /*margin: 50px 0;*/
  text-align: center;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>