const base = process.env.VUE_APP_API_BASE;

export async function Login(username, password) {
  const req = {
    username: username,
    password: password
  }
  const response = await fetch(`${base}/api/account/login`, {
    method: "POST",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(req),
  });
  let text = await response.json();

  return text;
}

export async function Register(username, password) {
  const req = {
    username: username,
    password: password
  }
  const response = await fetch(`${base}/api/account/register`, {
    method: "POST",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(req),
  });
  let text = await response.json();

  return text;
}

export async function Account() {
  const response = await fetch(`${base}/api/account`, {
    method: "GET",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();
  console.log(text);

  return text;
}


export async function Providers() {
  const response = await fetch(`${base}/api/provider`, {
    method: "GET",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();
  console.log(text);

  return text;
}

export async function Attempts() {
  const response = await fetch(`${base}/api/attempts`, {
    method: "GET",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();
  console.log(text);

  return text;
}

export async function Logout() {
  const response = await fetch(`${base}/api/account/logout`, {
    method: "POST",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();

  return text;
}

export async function VK(callback) {
  const url = `${base}/api/oauth/vk`;
  var vk = window.open(url, "VK integration", "height=480,width=640,modal=yes,alwaysRaised=yes");
  var timer = setInterval(function () {
    if (vk.closed) {
      clearInterval(timer);
      callback();
      console.log("closed");
    }
  }, 100);
}

export async function TG(callback) {
  const url = `${base}/api/oauth/telegram`;
  var vk = window.open(url, "VK integration", "height=480,width=640,modal=yes,alwaysRaised=yes");
  var timer = setInterval(function () {
    if (vk.closed) {
      clearInterval(timer);
      callback();
      console.log("closed");
    }
  }, 100);
}

export async function TelegramCallback(data, callback) {
  const req = {
    auth_date: data.auth_date,
    first_name: data.first_name,
    hash: data.hash,
    id: data.id,
    last_name: data.last_name,
    username: data.username
  }
  /*const response =*/ await fetch(`${base}/api/oauth/callback/telegram`, {
    method: "POST",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(req),
  });
  callback();
  // let text = await response.json();

  // return text;
}

export async function VKRemove(callback) {
  const response = await fetch(`${base}/api/provider/remove/1`, {
    method: "DELETE",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();
  callback();

  return text;
}

export async function TGRemove(callback) {
  const response = await fetch(`${base}/api/provider/remove/2`, {
    method: "DELETE",
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache",
    // credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Content-Type": "application/json",
      "Accept": "application/json",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    // body: JSON.stringify(req),
  });
  let text = await response.json();
  callback();

  return text;
}