<template>
  <div class="container profile">
    <div class="row">
      <div class="col-md-4 sidebar">
        <div class="company mb-5">
          <img
            class="mb-4 logo"
            src="@/assets/images/logo.svg"
            width="72"
            height="72"
          />
          <span class="title">Vertex of Art</span>
          <span class="version text-muted">{{
            $store.state.ApplicationVersion
          }}</span>
        </div>
        <div class="sidebar-block">
          <div class="title">Actions</div>
          <router-link to="/" class="action">Change nickname</router-link>
          <router-link to="/" class="action">Change password</router-link>
          <router-link to="/sign-out" class="action">Sign-out</router-link>
        </div>
        <div class="sidebar-block">
          <div class="title">Services</div>
          <a href="https://openocean.dumk.in" class="action">OpenOcean</a>
        </div>
      </div>
      <div class="col-md-8">
        <h1 class="h3 mb-3 mt-5 font-weight-normal header">
          {{ UserNickname }}
        </h1>

        <div class="integrations">
          <div class="title">Integrations:</div>
          <div class="provider">
            <span class="name vk">VK</span>
            <a class="action add" v-if="!IntegrationVKExists" @click="vk"
              >Add</a
            >
            <a class="action remove" v-else @click="vkRemove">Remove</a>
          </div>
          <br />
          <div class="provider">
            <span class="name vk">TG</span>
            <a class="action add" v-if="!IntegrationTGExists" @click="tg"
              >Add</a
            >
            <a class="action remove" v-else @click="tgRemove">Remove</a>
          </div>
        </div>

        <div class="attempts">
          <!-- <div class="title">Sign-in attempts:</div> -->
          <ul>
            <li v-for="(a, i) in Attempts" :key="i">
              <div>UserAgent: {{ a.UserAgent }}</div>
              <div>Time: {{ a.Time }}</div>
              <div>Successed: {{ a.Successed }}</div>
              <div>Provider: {{ a.Provider }}</div>
              <div>IP: {{ a.IP }}</div>
              <div>Error: {{ a.Error }}</div>
            </li>
          </ul>
        </div>

        <div ref="telegram"></div>

        <!-- <span>{{ this.$store.ApplicationVersion }}</span> -->

        <div class="actions"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Login, VK, TelegramCallback, VKRemove, TGRemove} from "@/api";

export default {
  name: "App-Home",
  components: {
    // HelloWorld
  },
  data: function () {
    return {
      loginEntered: "",
      passwordEntered: "",
    };
  },
  computed: {
    IntegrationVKExists() {
      return this.$store.state.Providers.VK;
    },
    IntegrationTGExists() {
      return this.$store.state.Providers.TG;
    },
    UserNickname() {
      return this.$store.state.Nickname;
    },
    Attempts() {
      return this.$store.state.Attempts;
    },
  },
  mounted() {
    if (!this.IsAuthorized) {
      this.addTelegramWidget();
    }
    // this.addTelegramWidget();
  },
  methods: {
    addTelegramWidget() {
      // console.log(this.$refs.telegram);
      // const telegramWidgetElement = this.$refs.telegram;
      // const script = document.createElement("script");
      // script.async = true;
      // script.src = "https://telegram.org/js/telegram-widget.js?15";
      // script.setAttribute("data-telegram-login", "Dumkin_bot");
      // script.setAttribute("data-size", "large");
      // script.setAttribute(
      //   "data-auth-url",
      //   "https://account.dumk.in/api/oauth/callback/telegram"
      // );
      // script.setAttribute("data-request-access", "write");
      // telegramWidgetElement.appendChild(script);
    },

    login: function () {
      Login(this.loginEntered, this.passwordEntered)
        .then((data) => {
          console.log(data);
          this.$store.dispatch("userRefresh");
        })
        .catch((error) => console.error(error));
    },
    vk: function () {
      VK(() => {
        this.$store.dispatch("userRefresh");
      });
    },
    tg: function () {
      const telegramWidgetElement = this.$refs.telegram;

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-widget.js?15";

      // const script = document.createElement("script");
      // script.setAttribute("data-telegram-login", "Dumkin_bot");
      // script.setAttribute("data-size", "large");
      // script.setAttribute(
      // "data-auth-url",
      // "https://account.dumk.in/api/oauth/callback/telegram"
      // );
      // script.setAttribute("data-request-access", "write");

      script.onload = () => {
        console.log("tg is loaded");

        window.Telegram.Login.auth(
          { bot_id: "517232063", request_access: true },
          (data) => {
            console.log("auth requested");
            if (!data) {
              // authorization failed
            }
            // TelegramCallback(data);
            TelegramCallback(data, () => {
              this.$store.dispatch("userRefresh");
            });
            this.$store.dispatch("userRefresh");
            //window.close();
          }
        );
      };

      telegramWidgetElement.appendChild(script);

      // TG(() => {
      //   // this.$store.dispatch("userRefresh");
      // });
    },
    vkRemove: function () {
      VKRemove(() => {
        this.$store.dispatch("userRefresh");
      });
    },
    tgRemove: function () {
      TGRemove(() => {
        this.$store.dispatch("userRefresh");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  background-color: white;

  margin-top: 10vh;

  // width: 100%;
  // max-width: 300px;

  border-radius: 0.5rem;

  & .sidebar {
    & .company {
      & img.logo {
        display: flex;
        width: 100%;
        margin: 40px 0;
      }
      & span.title {
        text-align: center;
        font-size: 20px;
        display: block;
      }
      & span.version {
        text-align: center;
        display: block;
      }
    }
    & .sidebar-block {
      & .title {
        color: #6c757d;
        text-transform: uppercase;
        font-size: 14px;
      }
      & .action {
        display: block;
        margin: 5px 0;
        padding: 10px 10px;
        color: #0073ef;
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
        border-radius: 0.5rem;

        &:hover {
          background-color: #e6e6e6;
        }
      }
    }
  }
}

.wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.header {
  font-size: 30px;

  /*margin: 50px 0;*/
  text-align: center;
}
.BerkshireSwash {
  font-family: "Berkshire Swash";
  font-size: 30px;

  /*margin: 50px 0;*/
  text-align: center;
}

.home {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
}

.photo {
  display: grid;
  justify-content: center;

  img {
    border-radius: 50%;
    width: 128px;
    height: 128px;
    border: 1px black solid;
  }
}

.nickname {
  font-size: 25px;
  font-weight: 700;
  margin: 10px 10px 10px 0;
  text-align: center;

  padding: 10px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 10px 10px 0;
}

.actions {
  a.action {
    text-decoration: none;
    display: block;
    line-height: 1.25em;
    margin: 5px 0;
  }

  a.action:visited {
    color: #4252ff;
  }
}

.provider {
  .name {
    display: inline;
    background-color: #5181b8;
    color: #fff;
    font-size: 12.5px;
    padding: 7px 16px 8px;
    line-height: 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px 0 0 4px;
  }

  .action {
    grid-area: action;
    border-radius: 0 4px 4px 0;
    grid-area: name;
    display: inline;
    background-color: #e6e6e6;
    color: #000;
    font-size: 12.5px;
    padding: 7px 16px 8px;
    line-height: 15px;
    text-align: center;
    text-decoration: none;

    &.add {
      &:hover {
        background-color: #21bd1c;
        color: #e6e6e6;
      }
    }

    &.remove {
      &:hover {
        background-color: #d54747;
        color: #e6e6e6;
      }
    }
  }
}
</style>