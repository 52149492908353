<template>
  <router-view />
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "App-View",
  components: {},
  // data: function() {
  //   return {
  //     ID: "",
  //     Nickname: ""
  //   };
  // },
  computed: {
    IsAuth() {
      return this.$store.state.IsAuth;
    },
    UserID() {
      return this.$store.state.ID;
    },
    UserNickname() {
      return this.$store.state.Nickname;
    },
  },
  created() {
    EventBus.$on("userRefresh", () => {
      if (this.$store.state.IsAuth) {
        if (this.$router.currentRoute.path !== "/") {
          this.$router.push({ name: "Home" });
        }
      } else {
        if (this.$router.currentRoute.path !== "/sign-in") {
          this.$router.push({ name: "Sign In" });
        }
      }
    });

    this.$store.dispatch("userRefresh");
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import "~bootstrap";

@font-face {
  font-family: "Berkshire Swash";
  // font-style: normal;
  // font-weight: 400;
  // font-display: swap;
  src: url("assets/fonts/BerkshireSwash.ttf");
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

.btn-block {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
}
</style>