import { createStore } from 'vuex'

import * as api from "@/api";
import EventBus from "@/event-bus";

export default createStore({
  state: {
    ApplicationVersion: process.env.VUE_APP_VERSION,

    IsLoaded: false,
    IsAuth: false,

    ID: "",
    Nickname: "",

    Providers: {
      VK: false,
      TG: false,
    },

    Attempts: []
  },
  mutations: {
    IsLoaded(state) {
      if (!state.IsLoaded) {
        EventBus.$emit('IsLoaded');
      }
      state.IsLoaded = true;
    },
    userRefreshAttempts(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        state.Attempts.push({
          UserID: payload[i].UserID,
          Provider: payload[i].Provider,
          IP: payload[i].IP,
          UserAgent: payload[i].UserAgent,
          Time: payload[i].Time,
          Successed: payload[i].Successed,
          Error: payload[i].Error,
        });
      }

      EventBus.$emit('userRefreshAttempts');
    },
    userRefresh(state, payload) {
      state.IsAuth = payload.IsAuth;
      state.ID = payload.ID;
      state.Nickname = payload.Username;

      state.Providers.VK = payload.Providers.includes("vk");
      state.Providers.TG = payload.Providers.includes("tg");

      this.commit('IsLoaded', null);
      EventBus.$emit('userRefresh');
    }
  },
  actions: {
    async userRefreshAttempts(context) {
      let attempts = await api.Attempts();

      context.commit('userRefreshAttempts', attempts);
    },
    async userInfo(context) {
      let userTask = api.Account();
      let providersTask = api.Providers();

      let user = await userTask;
      let providersRaw = await providersTask;

      let providers = [];
      if (providersRaw.isSuccess === true && providersRaw.document.length > 0) {
        providersRaw.document.forEach(element => {
          if (element.provider == 1) {
            providers.push("vk");
          }
          if (element.provider == 2) {
            providers.push("tg");
          }
        });
        
      }

      context.commit('userRefresh', {
        IsAuth: user.isSuccess === true,
        ID: user.document?.id,
        Username: user.document?.username,
        Providers: providers,
      });
    },
    async userRefresh(context) {
      context.dispatch("userInfo");
      // context.dispatch("userRefreshAttempts");
    }
  },
  modules: {
  }
})